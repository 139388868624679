import React from 'react';
import PropTypes from 'prop-types';
import useTranslations from '../../components/useTranslations';
import useResponsiveImage from '../useResponsiveImage';

import * as S from './styled';

const PostItem = ({
  lang,
  slug,
  date,
  title,
  description,
  image,
}) => {

  const {
    readMore
  } = useTranslations(lang);  

  return (
    <S.PostItemLink to={slug} lang={lang}>
      <S.PostItemWrapper>

          <div>
          <S.PostItemImg
            fluid={useResponsiveImage(image)}
            alt={title}
          />
          </div>

        <S.PostItemInfo>
          <S.PostItemDate>
            {date}
          </S.PostItemDate>
          <S.PostItemTitle>{title}</S.PostItemTitle>
          <S.PostItemDescription>{description}</S.PostItemDescription>
          <S.ReadMore>{readMore} →</S.ReadMore>
          
        </S.PostItemInfo>
      </S.PostItemWrapper>
    </S.PostItemLink>
  );
};

PostItem.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default PostItem;
